import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import ini from 'react-syntax-highlighter/dist/esm/languages/hljs/ini';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import properties from 'react-syntax-highlighter/dist/esm/languages/hljs/properties';
import { vs2015 as darkTheme, docco as lightTheme } from 'react-syntax-highlighter/dist/esm/styles/hljs/';

import { ThemeContext } from '../../context/ThemeState';
import SnippetButtons from './SnippetButtons';

const Snippet = styled.div`
  font-size: ${(props) => props.theme.fontSizes.m};
  line-height: 1.5;
  margin-top: ${(props) => props.theme.spaces.s};
  border-radius: ${(props) => props.theme.fontSizes.s};
  border: 1px solid var(--border);
  position: relative;
  scrollbar-color: var(--primary) transparent;

  /* Forced scrolling needed as buttons overlay. */
  pre > code {
    padding-right: 6rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const preCustomStyle = {
  borderRadius: 'inherit',
  padding: '.75rem',
};

const SnippetComp = (props) => {
  SyntaxHighlighter.registerLanguage('ini', ini);
  SyntaxHighlighter.registerLanguage('bash', bash);
  SyntaxHighlighter.registerLanguage('java', java);
  SyntaxHighlighter.registerLanguage('sql', sql);
  SyntaxHighlighter.registerLanguage('properties', properties);

  const { isDarkTheme } = useContext(ThemeContext);

  const pre = props.children.props;
  const language = pre.className.replace(/language-/, '');
  const code = pre.children.slice(0, -1);
  const fileName = pre.path === undefined ? 'trecho_de_codigo' : pre.path;
  const isNumbered = pre.numbered !== undefined;
  const lines = pre.lines === undefined ? [0] : genRange(pre.lines);

  function genRange(rows) {
    const a = rows.split(',').reduce((acc, cur) => {
      if (cur.includes('-')) {
        const range = cur.split('-');
        const numbers = [...Array(parseInt(range[1]) - parseInt(range[0]) + 1).keys()].map(
          (i) => i + parseInt(range[0])
        );
        acc.push(numbers);
      } else acc.push(parseInt(cur));
      return acc;
    }, []);

    return a.flat();
  }

  const highlightLine = (lineNumber) => {
    if (lines.includes(lineNumber)) {
      let style = { };
      style.display = 'block';
      // style.height = '100%';
      // style.whiteSpace = 'break-spaces';
      // style.padding = '0.2rem';
      style.backgroundColor = 'hsla(202, 98%, 37%, 0.15)';
      return { style };
    }
  };

  const highlightTerminalWords = (lineNumber) => {
    if (lines.includes(lineNumber)) {
      let style = { };
      style.backgroundColor = 'var(--pageText)';
      style.color = 'var(--bg)'
      return { style };
    }
  };

  // const highlightTerminalChars = (lineNumber) => {
  //   console.log();
  // };

  const lineNumberStyle = (lineNumber) => {
    let style = { paddingRight: '20px', color: '#999' };
    return { style };
  };

  return (
    <Snippet>
      {language !== 'text' && <SnippetButtons code={code} fileName={fileName} isDarkTheme={isDarkTheme} />}

      <SyntaxHighlighter
        language={language}
        style={isDarkTheme ? darkTheme : lightTheme}
        customStyle={preCustomStyle}
        wrapLines={true}
        lineProps={pre.words ? highlightTerminalWords : highlightLine}
        showLineNumbers={isNumbered}
        lineNumberProps={lineNumberStyle}
      >
        {code}
      </SyntaxHighlighter>
    </Snippet>
  );
};

export default SnippetComp;