import React from 'react';
import styled from '@emotion/styled';

export const Banner = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spaces.xl};
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSizes[`2xl`]};
`;

const BannerComp = () => {

  return (
    <Banner>
      <Title>
        <span role="img" aria-label="Emoji com dedo apontando para direita">
          👉
        </span>{' '}
        Algum conceito faltando?
      </Title>
      <div>
        Deixe um comentário no fim do <em>post</em> e escreverei a receita assim que possível.
      </div>
    </Banner>
  );
};

export default BannerComp;