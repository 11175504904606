import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import authorSignature from '../../data/authorSignatureQuery';
import CodeBlock from './Snippet';
import TableOfContents from './TableOfContents';
import Question from './Question';
import { H1, Subtitle, Description, H2, H3, InlineCode as Code, Article, Table, P } from '../shared/SystemDesign';
import Anchor from '../shared/Anchor';
import Navigation from './Navigation';
import Banner from './Banner'
import UnderConstruction from './UnderConstruction'

const ArticleComp = ({ children, post = {} }) => {
  const { title, subTitle, body, headings, description } = post;
  const author = authorSignature();

  return (
    <MDXProvider
      components={{
        a: Anchor,
        h2: H2,
        h3: H3,
        pre: CodeBlock,
        inlineCode: Code,
        table: Table,
        p: P,
        TableOfContents,
        Question,
        Navigation,
        Code,
        Banner,
        UnderConstruction
      }}
    >
      {body ? (
        <Article>
          <H1>{title}</H1>
          {subTitle && <Subtitle>{subTitle}</Subtitle>}
          <Description>{description.startsWith('Post') ? null : description}</Description>
          <MDXRenderer headings={headings}>{body}</MDXRenderer>
          <MDXRenderer>{author}</MDXRenderer>
        </Article>
      ) : (
        <Article>{children}</Article>
      )}
    </MDXProvider>
  );
};

export default ArticleComp;
