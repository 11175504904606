import React from 'react';
import styled from '@emotion/styled';

import fileDownload from 'js-file-download';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { FiCopy } from 'react-icons/fi';
import { FiDownload } from 'react-icons/fi';

import { IconButton } from '../shared/SystemDesign';

export const CopyButton = styled(IconButton)`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;

const DownloadButton = styled(CopyButton)`
  right: 3.5rem;
`;

const ReactTooltipStyled = styled(ReactTooltip)`
  font-size: ${(props) => props.theme.fontSizes.m};
`;

const SnippetButtonComp =  ({ code, fileName, isDarkTheme }) => {
  return (
    <>
      <DownloadButton aria-label="Baixa o código" onClick={() => fileDownload(code, fileName)}>
        <FiDownload />
      </DownloadButton>
      <CopyButton aria-label="Copia o código" onClick={() => copy(code)} data-tip="Copiado!" data-event="click">
        <FiCopy />
      </CopyButton>
      <ReactTooltipStyled
        globalEventOff="click"
        place="right"
        type={isDarkTheme ? 'light' : 'dark'}
        effect="solid"
        isCapture={true}
      />
    </>
  );
};

export default SnippetButtonComp;