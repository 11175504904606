import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FiSquare, FiCheckSquare, FiCheck, FiRefreshCcw, FiThumbsDown } from 'react-icons/fi';
import { IconButton } from '../shared/SystemDesign';

const checkbox = (props) => <input type="checkbox" {...props} />;
const Checkbox = styled(checkbox)`
  position: absolute;
  left: -100vw;
`;

const Label = styled.label`
  color: ${(props) => (props.correct === 'false' ? 'red' : 'none')};

  .checked {
    color: ${(props) => (props.correct === 'false' ? 'red' : 'var(--primary)')};
  }

  svg {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
    height: ${(props) => props.theme.spaces.l};
    width: ${(props) => props.theme.spaces.l};
    margin-right: ${(props) => props.theme.spaces.s};
    margin-left: ${(props) => props.theme.spaces.s};
    vertical-align: text-bottom;
  }
`;

const Fieldset = styled.fieldset`
  margin-top: ${(props) => props.theme.spaces.l};
  border: 1px solid var(--border);
  border-radius: ${(props) => props.theme.fontSizes.s};
  padding: 0 ${(props) => props.theme.spaces.s};

  div {
    margin-bottom: ${(props) => props.theme.spaces['2xs']};
  }
`;

const Legend = styled.legend`
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border: 1px solid var(--border);
  padding: ${(props) => props.theme.spaces['2xs']} ${(props) => props.theme.spaces.s};
  border-radius: ${(props) => props.theme.fontSizes.s};

  ::before {
    content: counter(questioncounter) '. ';
    counter-increment: questioncounter;
  }
`;

const Submit = styled(IconButton)`
  &.success,
  &.failure {
    cursor: not-allowed;
  }

  &.success > svg {
    cursor: not-allowed;
    transform: scale(1.3);
    stroke-width: 3;
    color: #090;
  }

  &.failure svg {
    cursor: not-allowed;
    transform: scale(1.2);
    stroke-width: 3;
    color: red;
  }
`;

const QuestionComp = ({ children, question, choices }) => {
  function initArray() {
    const array = [];
    choices.map((op) => array.push(false));
    return array;
  }

  const [checked, setCheckedArray] = useState(initArray());
  const [correctAnswers, setAnswers] = useState([]);
  const [verified, setVerified] = useState(false);
  const [cssClass, setCssClass] = useState('');

  const flipChecked = (index) => {
    checked[index] = !checked[index];
    setCheckedArray([...checked]);
  };

  const verifyAnswers = () => {
    checked.map((answer, index) => (correctAnswers[index] = answer === choices[index].correct));
    setAnswers([...correctAnswers]);
    setVerified(true);
    const allCorrect = correctAnswers.every((a, index) => a);
    setCssClass(allCorrect ? 'success' : 'failure');
  };

  const resetAnswers = () => {
    correctAnswers.map((a, index) => (correctAnswers[index] = undefined));
    setAnswers([...correctAnswers]);
    checked.map((c, index) => (checked[index] = false));
    setCheckedArray([...checked]);
    setVerified(false);
    setCssClass('');
  };

  return (
    <Fieldset>
      <Legend>{question}</Legend>

      {choices.map((op, index) => {
        return (
          <div key={index}>
            <Label correct={correctAnswers[index] + ''} disabled={verified}>
              <Checkbox onClick={() => flipChecked(index)} disabled={verified} />
              {checked[index] ? <FiCheckSquare className="checked" /> : <FiSquare />}
              {op.answer}
              {verified && !correctAnswers[index] ? <FiThumbsDown /> : null}
            </Label>
          </div>
        );
      })}

      <div style={{ textAlign: 'right' }}>
        <Submit onClick={resetAnswers} title="Redefine as respostas.">
          <FiRefreshCcw />
        </Submit>
        <Submit onClick={verifyAnswers} title="Valida as respostas." disabled={verified} className={cssClass}>
          <FiCheck />
        </Submit>
      </div>
    </Fieldset>
  );
};

export default QuestionComp;