import React from 'react';
import styled from '@emotion/styled';

export const NavigationLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spaces[`5xl`]};
`;

const NavigationComp = ({ children }) => {
  return (
    <NavigationLinks>
      {children.props.children[0]}
      {children.props.children[2]}
    </NavigationLinks>
  );
};

export default NavigationComp;