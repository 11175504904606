import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Article from '../components/Main/Article';

export const postQuery = graphql`
  query PostBySlug($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      headings {
        value
        depth
      }
      fields {
        slug
        date
      }
      frontmatter {
        title
        subTitle
        description
      }
    }
  }
`;

const PostTemplate = ({ data }) => {
  const {
    post: {
      body,
      frontmatter: { title, subTitle, description },
      headings,
    },
  } = data;

  const post = {
    title,
    subTitle,
    body,
    headings,
    description
  };

  return <Article post={post} />;
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PostTemplate;
