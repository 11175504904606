import React from 'react';
import Slugger from 'github-slugger';
import styled from '@emotion/styled';

import { H2 as NumberedH2 } from '../shared/SystemDesign';
import { A } from '../shared/Anchor';

const H2 = styled(NumberedH2)`
  ::before {
    content: none;
  }
`;

const TableOfContents = styled.div`
  line-height: 1.3;

  &.toc ol {
    margin-top: ${(props) => props.theme.spaces.l};
  }

  &.toc ol > * > ul {
    margin-top: ${(props) => props.theme.spaces.xs};
  }

  &.toc li {
    margin-top: ${(props) => props.theme.spaces.s};
  }

  &.toc li > * > li {
    margin-top: ${(props) => props.theme.spaces.xs};
  }
`;

const TableOfContentsComp =  ({ headings }) => {
  const slugger = new Slugger();

  let createList = () => {
    let lines = [];

    for (let i = 0; i < headings.length; ) {
      let j = i + 1;
      for (; j < headings.length && headings[j].depth !== 2; j++);

      if (i + 1 === j) {
        lines.push(
          <li key={headings[i].value}>
            <A href={'#' + slugger.slug(headings[i].value)}> {headings[i].value}</A>
          </li>
        );
        i++;
      } else {
        let childLines = [];
        for (let k = i + 1; k < j; k++) {
          childLines.push(
            <li key={headings[k].value}>
              <A href={'#' + slugger.slug(headings[k].value)}> {headings[k].value}</A>
            </li>
          );
        }

        lines.push(
          <li key={headings[i].value}>
            <A href={'#' + slugger.slug(headings[i].value)}> {headings[i].value}</A>
            <ul>{childLines}</ul>
          </li>
        );

        i = j;
      }
    }

    return lines;
  };

  return (
    <TableOfContents className="toc">
      <H2>Conteúdo</H2>
      <ol>{createList()}</ol>
    </TableOfContents>
  );
};

export default TableOfContentsComp;