import React from 'react';
import styled from '@emotion/styled';

export const UnderConstruction = styled.div`
  display: table;
  text-align: center;
  margin: ${(props) => props.theme.spaces.xl} auto;
  border-radius: ${(props) => props.theme.spaces.m};
  font-size: ${(props) => props.theme.fontSizes[`2xl`]};
`;

const UnderConstructionComp = () => {
  return (
    <UnderConstruction>
      <span style={{ marginRight: '0.5rem' }} role="img" aria-label="Emoji com placa de aviso">
        🚧
      </span>{' '}
      Artigo em construção!
    </UnderConstruction>
  );
};

export default UnderConstructionComp;
